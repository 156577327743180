<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Ledger</label>
                          <label style="color: red !important">*</label>

                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Ledger"
                          >
                            <v-select
                              v-model="ledger"
                              label="fullname"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="None"
                              :options="ledgeroption"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import { BFormGroup, BForm, BRow, BCol, BButton } from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
  },
  data() {
    return {
      ledger: "",
      ledgeroption: [],
      startdate: "",
      enddate: "",
      totalCr: 0,
      totalDr: 0,
      result: "",
      data: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "voucher_datetime",
          label: "Date",
        },
        // {
        //   field: "no",
        //   label: "No",

        // },

        {
          field: "opp_ledger_name",
          label: "Particulars",
        },
        {
          field: "voucher_type",
          label: "Type",
        },
        {
          field: "debit",
          label: "Dr Amount",
        },
        {
          field: "credit",
          label: "Cr Amount",
        },
        {
          field: "amount",
          label: "Balance",
        },
      ],
    };
  },
  mounted() {
    this.getLedger();
  },
  watch: {
    "$store.state.app.user_data"() {
      this.getLedger();
    },
  },
  methods: {
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    setVoucherType(type) {
      let obj = {
        sales: "Sales",
        cancel_sales: "Cancelled Sales",
        landsales: "bulk Land Sales",
        receipt: "Receipt",
      };
      return obj[type] ? obj[type] : type;
    },
    async searchData() {
      this.data = [];
      this.totalCr = 0;
      this.totalDr = 0;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          const data = {
            ledger: this.ledger.id,
            startdate: this.startdate,
            enddate: this.enddate,
          };
          await axios({
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
            url: `${this.baseApi}/ledgerstatement`,
          }).then((response) => {
            this.totalCr = 0;
            this.totalDr = 0;
            this.data = response.data.data;
            const text = {
              voucher_type: "Opening Balance",
              amount:
                this.data[0].op_balance_crdr == "Cr."
                  ? `${parseFloat(this.data[0].op_balance).toFixed(2)} Cr.`
                  : `${-1 * parseFloat(this.data[0].op_balance).toFixed(2)} Dr.`,
              balance: parseFloat(this.data[0].op_balance),
            };
            let arr = this.data[0].ledgervoucher;
            this.data[0].ledgervoucher = [];
            this.data[0].ledgervoucher.push(text);

            arr.map((item, index) => {
              item.ledger_name = item.ledger
                ? item.ledger.fullname
                  ? item.ledger.fullname
                  : item.ledger.name
                : "";
              item.opp_ledger_name = item.oppledger
                ? item.oppledger.fullname
                  ? item.oppledger.fullname
                  : item.oppledger.name
                : "";
              item.voucher_type = this.setVoucherType(item.voucher_type);
              this.data[0].ledgervoucher[index + 1] = item;
              if (parseFloat(this.data[0].ledgervoucher[index + 1].debit) == 0) {
                this.totalCr += parseFloat(item.credit);
                this.data[0].ledgervoucher[index + 1].balance =
                  this.data[0].ledgervoucher[index].balance +
                  parseFloat(this.data[0].ledgervoucher[index + 1].credit, 3);
                let balance = this.data[0].ledgervoucher[index + 1].balance;

                this.data[0].ledgervoucher[index + 1].amount =
                  balance >= 0
                    ? `${balance.toFixed(2)} Cr.`
                    : `${-1 * balance.toFixed(2)} Dr.`;
              } else {
                this.totalDr += parseFloat(item.debit);
                this.data[0].ledgervoucher[index + 1].balance =
                  this.data[0].ledgervoucher[index].balance -
                  parseFloat(this.data[0].ledgervoucher[index + 1].debit, 3);

                let balance = this.data[0].ledgervoucher[index + 1].balance;

                this.data[0].ledgervoucher[index + 1].amount =
                  balance >= 0
                    ? `${balance.toFixed(2)} Cr.`
                    : `${-1 * balance.toFixed(2)} Dr.`;
              }
            });
            const data = {
              credit: `${parseFloat(this.totalCr).toFixed(2)} Cr.`,
              debit: `${this.totalDr.toFixed(2)} Dr.`,
              amount: this.data[0].ledgervoucher[this.data[0].ledgervoucher.length - 1]
                .amount,
              voucher_type: "Total",
            };
            this.data[0].ledgervoucher.push(data);
            this.data = this.data[0].ledgervoucher;
            this.intervalID = setInterval(this.myCallback, 500);
          });
        }
      });
    },

    myCallback() {
      document.getElementsByTagName("tr")[
        document.getElementsByTagName("tr").length - 1
      ].className = "font-weight-bolder";

      document.getElementsByTagName("tr")[
        document.getElementsByTagName("tr").length / 2 + 1
      ].className = "font-weight-bolder";
      clearInterval(this.intervalID);
      this.$forceUpdate();
    },
    async getLedger() {
      this.ledgeroption = this.$store.state.app.all_users;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
